@media (min-width: 760px){
    .md\:py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
    .md\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}
.rounded {
    border-radius: .25rem;
}
.pb-1 { 
    padding-bottom: .25rem;
}
.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
}
.flex {
    display: flex;
}
.md\:w-1\/2 {
    width: 50%;
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.schedule-form-container {
  background-color: rgba(25, 27, 40, 0.75);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
}
  
  .schedule-form-container h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .schedule-form-container .form-group {
    margin-bottom: 1.5rem;
  }
  
  .schedule-form-container .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .schedule-form-container .form-group input,
  .schedule-form-container .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    background-color: rgb(45, 49, 66);
    color: #fff;
  }
  
  .schedule-form-container .form-group input[type='checkbox'] {
    width: auto;
    margin-right: 0.5rem;
  }
  
  .schedule-form-container .form-group .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .schedule-form-container .submit-button {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    background-color: rgb(14, 120, 248);
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: none;
    cursor: pointer;
  }
  
  .schedule-form-container .submit-button:hover {
    background-color: rgb(10, 90, 200);
  }
  .schedule-form-container .submit-button:disabled {
    background-color: grey;
    pointer-events: none;
  }