element.style {
  position: fixed;
  z-index: 9999;
  inset: 16px;
  pointer-events: none;
}
.w-11\/12 {
    width: 91.6667%;
}
.w-1\/3 {
  width: 33.333333%;
}
button, input, optgroup, select, textarea {
    line-height: inherit;
    color: inherit;
    padding: 0px;
}
.overflow-auto {
	overflow: auto;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgba(255,255,255,var(--tw-text-opacity));
}
.focus\:no-underline:focus, .hover\:no-underline:hover, .no-underline {
    text-decoration: none;
}
button {
    background-color: transparent;
    background-image: none;
}
.flex {
    display: flex;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.h-screen {
    height: 100vh;
}
.h-1\/3 {
  height: 33.333333%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
img,
video {
  max-width: 100%;
  height: auto;
}
.m-auto {
  margin: auto;
}
.loginBackground {
  /* background: linear-gradient(180deg, #57455f, #818ec3 45%); */
  background-image: url("../../public/background1.jpg");
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-12 {
  padding-top: 3rem;
}

.bg-transparent {
  background-color: transparent;
}

.form-control {
  background-color: #484857;
  color: #838693;
}

.lg\:w-1\/2 {
	width: 50%;
}
.m-auto {
	margin: auto;
}
.ml-0 {
  margin-left: 0 !important;
}
.form-error-label {
  text-align: left;
  margin: 5px 15px;
  color: #EF4444;
}
.input-error {
  border: 1px solid #EF4444;
}

@media (min-width: 1024px){
  .lg\:w-10\/12 {
    width: 83.333333%;
  }
}
@media (min-width: 768px) {
  .md\:w-7\/12 {
    width: 41.3333%;
  }
}
@media (min-width: 760px) {
  .md\:w-5\/12 {
    width: 41.666667%;
  }
  .md\:block {
    display: block;
  }
}