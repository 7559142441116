.loginBackground {
    /* background: linear-gradient(180deg,#57455f,#818ec3 45%); */
    background-image: url("../../public/background1.jpg");
    background-size: cover;
}

.loginBackgroundShadow {
    /* background: linear-gradient(180deg,#57455f,#818ec3 45%); */
    background: rgba(0,0,0,0.2);
}

.form-group-half {
	width: 49%;
}

.rounded-lg {
    border-radius: 0.5rem;
}

@media (min-width: 760px){

.md\:w-5\/12 {
    width: 50.666667%;
}
.md\:block {
    display: block;
}
.w-11\/12 {
    width: 91.6667%;
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

}

.form-control {
    /* background-color: #212332; */
    color: #838693;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.w-full {
    width: 100%;
}

img, video {
    max-width: 100%;
    height: 100%;
}

@media (min-width: 768px){
.md\:w-7\/12 {
  width: 91.3333%;
}
.md\:block {
    display: block;
}
}



