
.background1 {
  /* background-image: url("../../public/background1.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;
  
}

.background2 {
  /* background-image: url("../../public/background2.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background3 {
  /* background-image: url("../../public/background3.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background4 {
  /* background-image: url("../../public/background4.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background5 {
  /* background-image: url("../../public/background5.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background6 {
  /* background-image: url("../../public/background6.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background7 {
  /* background-image: url("../../public/background7.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background8 {
  /* background-image: url("../../public/background8.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background9 {
  /* background-image: url("../../public/background9.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background10 {
  /* background-image: url("../../public/background10.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background12 {
  /* background-image: url("../../public/background12.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background13 {
  /* background-image: url("../../public/background13.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background14 {
  /* background-image: url("../../public/background14.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background15 {
  /* background-image: url("../../public/background15.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background16 {
  /* background-image: url("../../public/background16.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background17 {
  /* background-image: url("../../public/background17.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background18 {
  /* background-image: url("../../public/background18.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background19 {
  /* background-image: url("../../public/background19.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background20 {
  /* background-image: url("../../public/background20.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

} 

 .background21 {
 /* background-image: url("../../public/background21.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background22 {
  /* background-image: url("../../public/background22.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}


.background22b {
  /* background-image: url("../public/background22blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background23 {
  /* background-image: url("../../public/background23.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}


.background23b {
  /* background-image: url("../public/background23blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background24 {
  /* background-image: url("../../public/background24.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}


.background24b {
  /* background-image: url("../public/background24blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background25 {
  /* background-image: url("../../public/background25.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}


.background25b {
  /* background-image: url("../public/background25blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background26 {
  /* background-image: url("../../public/background26.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}


.background26b {
  /* background-image: url("../public/background26blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background27 {
  /* background-image: url("../../public/background27.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

 
}


.background27b {
  /* background-image: url("../public/background27blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background28 {
  /* background-image: url("../../public/background28.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background28b {
  /* background-image: url("../public/backgroundblur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.background29 {
  /* background-image: url("../../public/background29.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

}

.background30 {
  /* background-image: url("../../public/background30.jpg"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;

} 

 .background31 {
 /* background-image: url("../../public/background31.webp"); */
 background-size: cover; 
 background-position: center;
 background-repeat: no-repeat;
 
}


.background31b {
  /* background-image: url("../public/background31blur.jpg"); */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  
 }

.homeeBackgroundShadow {
  /* background: linear-gradient(180deg,#57455f,#818ec3 45%); */
  background: rgba(0,0,0,0.1);
}