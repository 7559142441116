.search-container {
    display: flex;
}

@media (max-width: 760px){
    .search-input {
        width: 100%;
    }
}

@media (max-width: 510px){
    .search-container {
        flex-wrap: wrap;
        gap: 10px;
    }
    .search-container > a {
        width: 100%;
        margin: 0;
    }
    .search-input {
        width: 100%;
    }

    .meeting-tabs {
        flex-direction: column;
        gap: 7px;
    }
}