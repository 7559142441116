  .profile-page {
    margin: 20px;
  }
  .profile-page .profile-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .profile-page .profile-section {
    background-color: rgba(45, 49, 66, 0.85);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .profile-page .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-page .section-title {
    font-size: 1.5rem;
  }
  
  .profile-page .profile-avatar {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .profile-page .form-group {
    margin-bottom: 1rem;
  }
  
  .profile-page .form-group label {
    display: block;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .profile-page .form-group input,
  .profile-page .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    background-color: rgb(45, 49, 66);
    color: #fff;
  }
  
  .profile-page .form-group .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.75rem;
  }
  
  .profile-page .submit-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: rgb(14, 120, 248);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .profile-page .submit-button[disabled] {
    background-color: grey;
    pointer-events: none;
  }
  