
@tailwind base;
@tailwind components;
@tailwind utilities; 


html {
  -moz-tab-size: 4;
  tab-size: 4;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

.PhoneInputInput{
    background-color: #484857;
}

.PhoneInputCountrySelect {
  background-color: #484857;
}

.multiSelectContainer ul {
  background-color:  #212332;
}

.searchWrapper {
  border: 1px solid rgb(45, 49, 66);
  background-color: rgb(45, 49, 66);
}

@media (max-width: 1024px) {
  .flex-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 1124px) {
  .flex-upcoming {
    flex-wrap: wrap;
  }
  .wd-up {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #28293a;
  color: hsla(0, 0%, 100%, 0.8);
}

.font-bold {
  font-weight: 700;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.bg-transparent {
  background-color: transparent;
}

h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.my-2 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

element.style {
  position: fixed;
  z-index: 9999;
  inset: 16px;
  pointer-events: none;
}

@media (min-width: 760px) {
  .md\:w-full {
    width: 75%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.leading-tight {
  line-height: 1.25;
}
.w-full {
  width: 75%;
}

ol,
ul {
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ol,
ul {
  list-style: none;
}


.w-1\/12 {
  width: 8.333333%;
}

.w-2\/5 {
  width: 40%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
img,
video {
  max-width: 100%;
  height: auto;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img {
  border-style: solid;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.cursor-pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
@media (min-width: 1024px) {
  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:block {
    display: block;
  }
}
.p-6 {
  padding: 1.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.items-center {
  align-items: center;
}
.my-3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

*,
:after,
:before {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}
*,
:after,
:before {
  --tw-shadow: 0 0 transparent;
}

.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
.overflow-y-auto {
  overflow-y: auto;
}

@media (min-width: 760px) {
  .md\:mx-3 {
    margin-left: 0.5rem;
    margin-right: 0.75rem;
  }
}

@media (max-width: 1024px) {
  .flex-wrap {
    flex-wrap: wrap;
  }
  .p-2 {
    padding: 0.5rem;
  }
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.ml-2 {
  margin-left: 0.5rem /* 8px */;
}
.mt-3 {
  margin-top: 0.75rem /* 12px */;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

*,
:after,
:before {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid;
}
element.style {
  background-color: rgb(242, 109, 33);
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.font-bold {
  font-weight: 700;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .md\:w-1\/2 {
    width: 50%;
  }
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.px-1 {
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
}

@media (min-width: 768px) {
  .md\:py-3 {
    padding-top: 0.75rem /* 12px */;
    padding-bottom: 0.75rem /* 12px */;
  }
  .md\:px-2 {
    padding-left: 0.5rem /* 8px */;
    padding-right: 0.5rem /* 8px */;
  }
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.w-11\/12 {
  width: 91.666667%;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[role="button"],
button {
  cursor: pointer;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  color: inherit;
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 1024px) {
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
}
.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}
.left-3 {
  left: 0.75rem;
}
.top-1\/2 {
  top: 50%;
}
.absolute {
  position: absolute;
}
.pointer-events-none {
  pointer-events: none;
}
.justify-between {
  justify-content: space-between;
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.rounded {
  border-radius: 0.25rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
label {
  display: block;
  margin-top: 10px;
}
*,
:after,
:before {
  --tw-shadow: 0 0 transparent;
}

*,
:after,
:before {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid;
}

div {
  display: block;
}
